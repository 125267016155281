import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constantes } from './constants.property';

import { budget } from '../servicesModels/budget';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  constructor(private httpClient: HttpClient) { }

  public makeBudget(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<budget>(`${Constantes.IPserver}:${Constantes.Port}/api/budgets`, obj, httpOptions)

  }
  public makeplaceBudget(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post<budget>(`${Constantes.IPserver}:${Constantes.Port}/api/places/${obj.placeId}/budgets`, obj, httpOptions)

  }
  public updateBudget(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.patch<budget>(`${Constantes.IPserver}:${Constantes.Port}/api/budgets/${obj.id}`, obj, httpOptions)

  }
  public getAllplacesBudgets(condition, attr, value, arith, like = false) {
    let place = JSON.parse(localStorage.getItem('place'))
    let placeid;
    if (place) {
      placeid = place.id
    }
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/places/${placeid}/budgets`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<budget[]>(service, httpOptions)

  }
  public getAllBudgets(condition, attr, value, arith, like = false) {

    let service = `${Constantes.IPserver}:${Constantes.Port}/api/budgets`
    if (condition && attr) {
      if (like) {
        // ?filter={"where":{"title":{"like":"someth.*","options":"i"}}}
        let likeness = '?filter='
        service += likeness;
        service += JSON.stringify(value)
      } else {

        let cond = '?filter[' + condition + ']'
        let attribute = '[' + attr + ']'
        if (cond && cond != '?filter[]') {
          service += cond
          if (attribute) {
            service += attribute
          }
          if (value) {
            if (arith == 'gte') {
              console.log('ES TIPO DATEEEE EL VALUE useGte', value)
              service += '[gte]'
            }
            if (arith == 'gt') {
              console.log('ES TIPO DATEEEE EL VALUE gtgt', value)
              service += '[gt]'
            }
            if (arith == 'lt') {
              console.log('ES TIPO DATEEEE EL VALUE uselt', value)
              service += '[lt]'
            }
            if (arith == 'lte') {
              console.log('ES TIPO DATEEEE EL VALUE use lte', value)
              service += '[lte]'
            }
            if (arith == 'between') {
              console.log('ES TIPO DATEEEE EL VALUE usebetween', value)
              service += '[between]'
            }
            if (arith == 'like') {
              console.log('ES TIPO DATEEEE EL VALUE uselike', value)
              service += '[like]'
            }
            if (arith == 'nlike') {
              console.log('ES TIPO DATEEEE EL VALUE nlike', value)
              service += '[nlike]'
            }

            let val = '=' + value
            service += val
          }
        }
      }
    }
    console.log('CONDITION', condition)
    console.log('attribute', attr)
    console.log('value', value)
    console.log('SERVICE URL', service)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<budget[]>(service, httpOptions)
  }

  getBudgetById(id) {
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/budgets/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.get<budget>(service, httpOptions)
  }
  deleteBudget(id){
    let service = `${Constantes.IPserver}:${Constantes.Port}/api/budgets/${id}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.delete<budget>(service, httpOptions)
  }
}
// http://localhost:3000/api/budgets?filter[where][address]=Almagro